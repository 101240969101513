import { useState, useContext, useEffect, Fragment } from "react";
import { Sim } from "../Interfaces/ISim";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { GetSimsByUserId, UpdateSim } from "../Services/SimService";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import UserSimsTable from "../components/UserSimspage/UserSimsTable";
import SimUpload from "../components/UserSimspage/SimUpload";
import Toolbar from "../components/UI/Toolbar";
import { ToolbarButtonProps } from "../components/UI/ToolbarButton";
import { IoChevronBackOutline } from "react-icons/io5";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../components/UI/ConfirmationModal";
import * as XLSX from "xlsx";

const defaultConfirmation: ConfirmationModalProps = {
  show: false,
  titleText: "",
  text: "",
  buttonText: "",
  handleOk: null,
  handleClose: null,
  showActionButton: false,
};

const UserSimsPage = () => {
  const authCtx = useContext(AuthContext);
  const [userSims, setUserSims] = useState<Sim[]>();
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(); 
  const [pageSize, setPageSize] = useState<number>(20);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSimIds, setSelectedSimIds] = useState<string[]>([]);
  const navigate = useNavigate();
  let { userId } = useParams();
  const [changedData, setChangedData] = useState<Sim[]>();
  const [modalData, setModalData] =
    useState<ConfirmationModalProps>(defaultConfirmation);

  const OnModalClose = () => {
    setModalData(defaultConfirmation);
  };

  const BackToUserAdmin = () => {
    const url = "/userAdmin";
    navigate(url);
  };
  let toolbarButtons: ToolbarButtonProps[] = [
    { onClick: { BackToUserAdmin }, label: "Back", disabled: false, icon: {} },
  ];

  const GetSimsForUser = async (pageNumber: number, pageSize: number) => {
    setIsLoading(true);
    const response = await GetSimsByUserId(
      userId,
      pageNumber,
      pageSize,
      authCtx.token
    );
    if (response.success) {
      setUserSims(response.data.data);
      setTotalRecords(response.data.total);
      setPageSize(pageSize);
      setCurrentPage(pageNumber);
      setTotalPages(response.data.totalPages); 
    } else {
      if (response.data === "Not Found") {
        setUserSims([]);
        setTotalRecords(0);
      } else {
        setErrorMessage("Error getting sims");
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetSimsForUser(1, 20);
  }, []);

  const UploadNewData = async (data: Sim[]) => {
    let allDataUploaded = true;
    for (var sim of data) {
      let token = authCtx.token;
      let serverSim: Sim = userSims.find(
        (x) => x.id == sim.id || x.serialNo === sim.serialNo
      );
      if(serverSim.validated){
        continue; 
      }
      if (serverSim) {
        serverSim.arrivalDate = sim.arrivalDate ? sim.arrivalDate : null;
        serverSim.departureDate = sim.departureDate ? sim.departureDate : null;
        serverSim.referenceNumber = sim.referenceNumber ? sim.referenceNumber : null;

        const response = await UpdateSim(serverSim, token, userId);

        if (await !response.success) {
          allDataUploaded = false;
        }
      }
    }

    if (allDataUploaded) {
      GetSimsForUser(1, 10);
    } else {
      setModalData({
        show: true,
        titleText: "Failure",
        text: "Failed To Upload Data",
        buttonText: "",
        handleOk: null,
        handleClose: { OnModalClose },
        showActionButton: false,
      });
    }
  };

  const SaveData = async () => {
    await UploadNewData(changedData);

    window.location.reload();
  };

  const ValidateSelected = async () => {
    setIsLoading(true);
    let validationSuccess = true;
    let token = authCtx.token;
    for (var id of selectedSimIds) {
      let sim: Sim = userSims.find((x) => x.id === id);
      if (sim) {
        sim.validated = true;
        const response = await UpdateSim(sim, token, userId);
        if (!response.success) {
          validationSuccess = false;
        }
      } else {
        validationSuccess = false;
        return;
      }
    }

    if (validationSuccess) {
      await GetSimsForUser(1, 20);
    } else {
      setErrorMessage("Failed to validate");
    }

    setIsLoading(false);
  };

  const downloadSpreadSheet = async () => {
    
    let fileName: string = `sims.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(userSims);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      <Toolbar buttons={toolbarButtons} title="User SIMs" />
      {isLoading && <LoadingSpinner />}
      {!isLoading && errorMessage.length > 0 && <p>{errorMessage}</p>}
      {!isLoading && errorMessage.length == 0 && (
        <Fragment>
          <UserSimsTable
            setIsloading={setIsLoading}
            userSims={userSims}
            setSelectedSimIds={setSelectedSimIds}
            setChangedData={setChangedData}
            currentPageNum={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            total={totalRecords}
            refresh={GetSimsForUser}
          />
          <div className="d-grid justify-content-center gap-2 d-md-flex">
            <Button
              className="btn btn-secondary mt-3"
              onClick={() => {
                navigate("/UserAdmin");
              }}
            >
              <IoChevronBackOutline /> Return to User Admin
            </Button>
            <div className="d-grid justify-content-center gap-2 d-md-flex">
                      <Button
                        className="btn btn-primary mt-3"
                        disabled={!changedData || changedData.length == 0}
                        onClick={SaveData}
                      >
                        Save
                      </Button>
                    </div>
            <Button
              className="btn btn-primary mt-3"
              disabled={selectedSimIds.length === 0}
              onClick={ValidateSelected}
            >
              Validate
            </Button>
            <Button
              className="btn btn-primary mt-3"
              disabled={selectedSimIds.length === 0}
              onClick={downloadSpreadSheet}
            >
              Download
            </Button>
          </div>
          <SimUpload
            userId={userId}
            setIsLoading={setIsLoading}
            userSims={userSims}
          />
        </Fragment>
      )}
      <ConfirmationModal
        show={modalData.show}
        titleText={modalData.titleText}
        text={modalData.text}
        buttonText={modalData.buttonText}
        handleOk={modalData.handleOk}
        handleClose={modalData.handleClose}
        showActionButton={modalData.showActionButton}
      />
    </>
  );
};

export default UserSimsPage;
