import { HiChevronLeft, HiChevronRight} from "react-icons/hi";

export type PaginationProps = {
  currentPage: number;
  pageSize: number;
  totalpages: number; 
  total: number;
  onChange(pageNumber: number, pageSize: number);
};

const Pagination = (props: PaginationProps) => {
  const NextPage = () => {
    props.onChange(props.currentPage + 1, props.pageSize);
  };

  const PreviousePage = () => {
    props.onChange(props.currentPage - 1, props.pageSize);
  };

  const NewPageSize = (value: number) => {
    props.onChange(props.currentPage, value);
  };

  return (
    <>
      <div className="pagination">
        <button onClick={PreviousePage} disabled={props.currentPage < 2}>
          <HiChevronLeft className="icon" />
        </button>
        <button className="current">{props.currentPage}</button>
        <button
          onClick={NextPage}
          disabled={props.currentPage == props.totalpages}
        >
          <HiChevronRight className="icon" />
        </button>
        <div className="m-3">
          <label>{props.pageSize} per page:</label>
          <select
            value={props.pageSize}
            onChange={(e) => {
              NewPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Pagination;
