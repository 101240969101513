import { useEffect, useState, useContext, Fragment } from "react";
import { Card, Container, Row, Col, Spinner, Button, Stack } from "react-bootstrap";
import AcceptTerms from "../components/OrganisationPage/AcceptTerms";
import UploadDocuments from "../components/OrganisationPage/UploadDocuments";
import { User } from "../Interfaces/IUser";
import { DocumentInfo } from "../Interfaces/IDocumentInfo";
import { GetUserById } from "../Services/UserServices";
import AuthContext from "../store/auth-context";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { GetUserDocumentsData } from "../Services/DocumentService";
import { GetSimsByUserId } from "../Services/SimService";
import ConfirmationModal, { ConfirmationModalProps } from "../components/UI/ConfirmationModal";
import IDInfo from "../components/OrganisationSimsPage/IDInfo";
import { BsFillSimFill } from "react-icons/bs";

const defaultConfirmation: ConfirmationModalProps = {
  show: false,
  titleText: "",
  text: "",
  buttonText: "",
  handleOk: null,
  handleClose: null,
  showActionButton: false,
};

const defaultDocInfo: DocumentInfo = {
  firstName: "",
  lastName: "",
  refName: "",
  expirationDate: undefined,
  documentUploaded: false,
  countryOfIssue: "",
};

const OrganisationPage = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [documentsUploaded, setDocumentsUploaded] = useState(false);
  const [user, setUser] = useState<User>();
  const [userHasSims, setUserHasSims] = useState(false);
 
  const [docNeedValidation, setDocNeedValidation] = useState(false);
  let { userId } = useParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalTitleText, setModalTitleText] = useState("Documents Uploaded"); 
  const [modalText, setModalText] = useState("Thank you for uploading your documents.")
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo>(defaultDocInfo);

  const location = useLocation();

  const documentUploaded = () => {
    setShowConfirmation(true);
  };

  const docUploadFailed =(error)=> {
    setModalTitleText("Failed to Upload");
    setModalText(error); 
    setShowConfirmation(true);
  }

  const onConfirmation = () => {
    setShowConfirmation(false);
    if(modalTitleText == "Documents Uploaded"){
      setDocumentsUploaded(true);
      window.location.reload();
    }
    
  };
  const getUserData = async () => {
    if (!userId) {
      let decodedToken = authCtx.decodedToken;
      userId = decodedToken.id;
    }

    let theUserHasSims = false;
    const simsForUserResponse = await GetSimsByUserId(userId, 1, 20, authCtx.token);
    if (simsForUserResponse.success && simsForUserResponse.data.data.length > 0) {
      setUserHasSims(true);
      theUserHasSims = true;
    }

    GetUserById(userId, authCtx.token).then((result) => {
      if (!result.success) {
      } else {
        let user: User = result.data;
        user.id = userId;
        if (user.admin) {
          navigate("/UserAdmin");
          return;
        }

        setUser(user);
        setAcceptedTerms(user.termsAccepted);
        const documents = GetUserDocumentsData(user.id, authCtx.token).then((result) => {
          if (user.termsAccepted && result.data !== "notFound" && theUserHasSims) {
            let url = "/OrganisationSims/" + userId;
            navigate(url);
          } else {
            if (result.data !== "notFound") {
              setDocumentsUploaded(true);
            } else {
              setDocumentsUploaded(false);
            }
          }
          setIsLoading(false);
        });
      }
    });
  };

  const GetUserDocData = async () => {
    const docInforespose = await GetUserDocumentsData(userId, authCtx.token);
    if (docInforespose.success) {
      let returnedData = docInforespose.data;
      let newDocInfo: DocumentInfo = {
        firstName: returnedData.firstName,
        lastName: returnedData.lastName,
        expirationDate: returnedData.expirationDate,
        refName: returnedData.referenceNumber,
        countryOfIssue: returnedData.countryOfIssue,
        documentUploaded: true,
      };

      setDocumentInfo(newDocInfo);
    }
  };

  useEffect(() => {
    getUserData();
    GetUserDocData();
  }, [location.pathname]);

  return (
    <Fragment>
      {isLoading && (
        <div className="loading">
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> <span>Loading...</span>
        </div>
      )}
      <Stack>
        {!isLoading && !acceptedTerms && (
          <Container>
            <Row>
              <AcceptTerms user={user} userId={userId} termsAccepted={setAcceptedTerms} />
            </Row>
          </Container>
        )}
        {!isLoading && !documentsUploaded && (
          <Container>
            <Row>
              <UploadDocuments
                user={user}
                setError={docUploadFailed}
                isLoading={setIsLoading}
                setDocumentUploaded={setDocumentsUploaded}
                setDocumentNeedValidation={setDocNeedValidation}
                documentUploaded={documentUploaded}
              />
            </Row>
          </Container>
        )}
        {!isLoading && documentsUploaded && !userHasSims && (
          <Container>
            <IDInfo docInfo={documentInfo}></IDInfo>
            <h4>
              <BsFillSimFill />
              SIM List (Pending)
            </h4>
            <Card className="my-4 p-5">
              <Row className="align-items-center justify-content-center">
                Your SIM list is currently getting processed. This will be available soon.
              </Row>
            </Card>
          </Container>
        )}
      </Stack>
      <ConfirmationModal
        show={showConfirmation}
        titleText={modalTitleText}
        text={modalText}
        buttonText={"ok"}
        handleOk={onConfirmation}
        handleClose={onConfirmation}
        showActionButton={false}
      />
    </Fragment>
  );
};

export default OrganisationPage;
