import { Fragment } from "react";
import { Modal } from "react-bootstrap";

 export type ConfirmationModalProps ={
    show: boolean, 
    titleText:string,
    text: string, 
    buttonText: string,
    handleOk: any
    handleClose: any
    showActionButton: boolean
}

const ConfirmationModal = (props: ConfirmationModalProps) =>{

    return (
        <Modal show={props.show}>
          <Modal.Header>
            <Modal.Title>{props.titleText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.text}</Modal.Body>
          <Modal.Footer>  
              <>
              {props.showActionButton ? (
                  <Fragment>
                        <button className="btn btn-primary" onClick={props.handleOk}>
                            {props.buttonText}
                            </button>
                            <button className="btn btn-default" onClick={props.handleClose}>
                            Cancel
                         </button>
                  </Fragment>
              ) : (
                    <button className="btn btn-default" onClick={props.handleClose}>
                    Close
                </button>
              )}
              </>           
          </Modal.Footer>
        </Modal>
      );
};

export default ConfirmationModal