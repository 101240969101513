import { Formik } from "formik";
import { useState, useContext, Fragment } from "react";
import * as Yup from "yup";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { IdentityDocument } from "../../Interfaces/IIdentityDocument";
import { User } from "../../Interfaces/IUser";
import { UploadDocumentData, GetUserDocumentsData, UploadDocumentFile, DeleteDocumentData } from "../../Services/DocumentService";
import { GetSimsByUserId } from "../../Services/SimService";
import AuthContext from "../../store/auth-context";
import Toolbar from "../../components/UI/Toolbar";
import { useNavigate } from "react-router-dom";
import { BsFillLockFill } from "react-icons/bs";
import { error } from "console";

interface UploadDocumentsProps {
  user: User;
  setError(string): any;
  isLoading(loading: boolean): any;
  setDocumentUploaded(uploaded: boolean): any;
  setDocumentNeedValidation(needValidat: boolean): any;
  documentUploaded(): any;
}

const UploadDocuments = (props: UploadDocumentsProps) => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const acceptedFileTypes = ["image/png", "image/jpeg", "application/pdf"];
  const authCtx = useContext(AuthContext);
  const saveFile = (event: any): void => {
    let file = event.currentTarget.files[0];
    if (!acceptedFileTypes.includes(file.type)) {
      setErrorMessage("File type is incorrect. Please upload a PNG, JPEG or PDF. Max file size: 5MB.");
      return;
    }
    if (file.size / 5001 > 5001 * 1) {
      setErrorMessage("File too big");
      return;
    }
    setErrorMessage("");
    setFile(event.currentTarget.files[0]);
    setFileUploaded(true);
  };

  const UploadNewDocument = async (values) => {
    if (!fileUploaded) {
      setErrorMessage("Please upload a valid Photo ID");
      return;
    }
    props.isLoading(true);
    setErrorMessage("");

    const doc: IdentityDocument = {
      firstName: values.FirstName,
      lastName: values.LastName,
      referenceNumber: values.ReferenceNumber,
      countryOfIssue: values.CountryOfIssue,
      expirationDate: new Date(),
      id: null,
      userId: props.user.id,
      validated: false,
    };

    const documentDataResponse = await UploadDocumentData(doc, authCtx.token);
    if (!documentDataResponse.success) {
      props.isLoading(false);
      return;
    }

    const UserDocument = await GetUserDocumentsData(props.user.id, authCtx.token);

    if (!UserDocument.success || UserDocument.data == "notFound") {
      setErrorMessage("Failed To Upload");
      props.isLoading(false);
      return;
    }

    const uploadDoc = await UploadDocumentFile(file, UserDocument.data.id, authCtx.token);

    if (uploadDoc.success) {
      props.documentUploaded();
      props.isLoading(false);
    } else {
      await DeleteDocumentData(UserDocument.data.id, authCtx.token); 
      props.isLoading(false);
      props.setError("Failed to upload image");
      
    }

    
  };

  const validationScheme = Yup.object().shape({
    FirstName: Yup.string().required("Please enter First Name"),
    LastName: Yup.string().required("Please enter Last Name"),
    ReferenceNumber: Yup.string().required("Please enter Reference Number"),
    ExpirationDate: Yup.string().required("Please enter the Expiration Date"),
    CountryOfIssue: Yup.string().required("Please enter Country of Issue"),
  });

  return (
    <Fragment>
      {!props.isLoading && fileUploaded && errorMessage.length === 0 && (
        <Row className="align-items-center justify-content-center">
          <Col md={8} lg={8} xl={8}>
            <div>
              <span>
                Thank you for your submission we will let you know when your SIM information is ready to view.
              </span>
            </div>
          </Col>
        </Row>
      )}
      <Row className="align-items-center justify-content-center">
        <Col md={8} lg={8} xl={8}>
          <h4 className="text-center">
            <BsFillLockFill />
            Please upload ID document information
          </h4>
          {errorMessage.length > 0 && (
            <span> {errorMessage}</span>
          )}

          <Formik
            initialValues={{
              FirstName: "",
              LastName: "",
              ReferenceNumber: "",
              CountryOfIssue: "",
              ExpirationDate: "",
              FileUpload: "",
            }}
            onSubmit={(values, actions) => {
              UploadNewDocument(values);
            }}
            validationSchema={validationScheme}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2 }}>
                <Row className="mt-5">
                  <Form onSubmit={handleSubmit}>
                    <Card className="p-5">
                      {errorMessage.length > 0 && <Alert variant="danger">{errorMessage}</Alert>}
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          name="FirstName"
                          type="text"
                          onChange={handleChange}
                          value={values.FirstName}
                        ></Form.Control>
                        {errors.FirstName && touched.FirstName && (
                          <div className="input-feedback">{errors.FirstName}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          name="LastName"
                          type="text"
                          value={values.LastName}
                          onChange={handleChange}
                        ></Form.Control>
                        {errors.LastName && touched.LastName && <div className="input-feedback">{errors.LastName}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Upload Photo ID (e.g. Passport, ID Card or Driving licence.)</Form.Label>
                        <Form.Control
                          name="FileUpload"
                          type="file"
                          onChange={(e) => {
                            saveFile(e);
                          }}
                        ></Form.Control>
                        <Form.Text className="text-muted">File format: PNG, JPEG or PDF. Max file size: 5MB.</Form.Text>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Reference Number</Form.Label>
                        <Form.Control
                          name="ReferenceNumber"
                          type="text"
                          value={values.ReferenceNumber}
                          onChange={handleChange}
                        ></Form.Control>
                        {errors.ReferenceNumber && touched.ReferenceNumber && (
                          <div className="input-feedback">{errors.ReferenceNumber}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Country of Issue</Form.Label>
                        <Form.Control
                          name="CountryOfIssue"
                          type="text"
                          value={values.CountryOfIssue}
                          onChange={handleChange}
                        ></Form.Control>
                        {errors.CountryOfIssue && touched.CountryOfIssue && (
                          <div className="input-feedback">{errors.CountryOfIssue}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Expiration Date</Form.Label>
                        <Form.Control
                          name="ExpirationDate"
                          type="date"
                          value={values.ExpirationDate}
                          onChange={handleChange}
                        />
                        {errors.ExpirationDate && touched.ExpirationDate && (
                          <div className="input-feedback">{errors.ExpirationDate}</div>
                        )}
                      </Form.Group>
                    </Card>

                    <div className="d-grid justify-content-center gap-2 d-md-flex">
                      <Button type="submit" className="btn btn-primary">
                        Submit information
                      </Button>
                    </div>
                  </Form>
                </Row>
              </Col>
            )}
          </Formik>
        </Col>
      </Row>
    </Fragment>
  );
};
export default UploadDocuments;
