import { IdentityDocument } from "../Interfaces/IIdentityDocument";
import { DocumentFileDownload} from "../Interfaces/IDocumentFileDownload"
import config from "../store/app.config";

const baseUrl = config['API_BASE_URL']

export const GetUserDocumentsData = async (id: string , token: string) =>{

    try
    {
        const url = `${baseUrl}api/document/user/${id}`
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            })
        }); 

        if (await response.ok){
            return {"success": true, "data": await response.json() }; 
        } else {
            if (response.status == 404){
                return {"success": true, "data": "notFound"}
            }
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const UploadDocumentData = async (document: IdentityDocument, token) =>{

    let request ={
        userId: document.userId,
        firstName: document.firstName, 
        lastName: document.lastName, 
        referenceNumber: document.referenceNumber, 
        countryOfIssue: document.countryOfIssue, 
        expirationDate: document.expirationDate
    }

    try
    {    
        const url = `${baseUrl}api/document/`
        const response = await fetch(url, {
            method: "POST", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",                         
            }),  body: JSON.stringify(request),
        }); 

        if (await response.ok){
            return {"success": true, "data": "" }; 
        } else {        
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const UpdateDocumentData = async(document: IdentityDocument, token: string)=>{

    let request ={
        firstName: document.firstName, 
        lastName: document.lastName, 
        referenceNumber: document.referenceNumber, 
        countryOfIssue: document.countryOfIssue, 
        expirationDate: document.expirationDate,
        validated: document.validated
    }

    try
    {  
        const url = `${baseUrl}api/document/${document.id}`
        const response = await fetch(url, {
            method: "PATCH", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }), body: JSON.stringify(request),
        });

        if (await response.ok){
            return {"success": true, "data": "" }; 
        } else {
            return {"success": false, "data": "Failure"}
        }

    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const UploadDocumentFile = async (file, documentId, token)=>{

    try
    {
        var data = new FormData(); 
        data.append("image", file); 
        
        const url = `${baseUrl}api/document/${documentId}/image`
     
        const response = await fetch(url, {
            method: "POST", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,      
            }), body: data,
        });

        if (await response.ok){
            return {"success": true, "data": "" }; 
        } else {    
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error){
        console.log(error)
        return {"success": false, "data": error}
    }
}

export const DeleteDocumentData = async ( documentId: string, token) =>
{
    try
    {  
        const url = `${baseUrl}api/document/${documentId}`
        const response = await fetch(url, {
            method: "DELETE", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            })
        });

        if (await response.ok){
            return {"success": true, "data": "" }; 
        } else {
            return {"success": false, "data": "Failure"}
        }

    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

const convertStreamToTextForImage = async (logoResponse) => {
    try {
      const reader = await logoResponse.body.getReader();
  
      const stream = await new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              push();
            });
          }
  
          push();
        },
      });
  
      const newResponse = await new Response(stream, {
        headers: { "Content-Type": "text/html" },
      }).text();
  
      return newResponse;
    } catch (Err) {
      console.log(Err);
      return null;
    }
  };

export const DownloadDocumentFile = async(documentId: string, token: string)=>
{
    try
    {
        const url = `${baseUrl}api/document/${documentId}/image`
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,   
            })
        }); 

        if (await !response.ok){
            return {"success": false, "data": response.text }; 
        } else {     
            let imageString = await convertStreamToTextForImage(response); 
            
            if (await imageString !== null){
                let documentObject: DocumentFileDownload = JSON.parse(imageString);
                return {"success": true, "data": documentObject}
            } else {
                return {"success": false, "data": ""}
            }
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const GetDocumentById =async (docId: string, token:string)=>{

    try
    {
        const url = `${baseUrl}api/document/${docId}`;
        
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            })
        }); 

        if (await !response.ok){
            return {"success": false, "data": response.text }; 
        } else {
           return {"success": true, "data":  await response.json()}   
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}
