import { Fragment } from "react";
import { Container, Navbar } from "react-bootstrap";
import logo from "../../../src/cellhire-white.svg";
import ProfileMenu from "./ProfileMenu";

const Header = () => {
  let menuBarClass = "dark";
  return (
    <Fragment>
      <Navbar className={menuBarClass}>
        <Container fluid>
          <Navbar.Brand>
            <img src={logo} width="100" height="60" alt="Cellhire" />
          </Navbar.Brand>
          <ProfileMenu />
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default Header;
