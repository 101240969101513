import { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import UserTable from "../components/UserAdmin/UserTable";
import { GetUsersList } from "../Services/UserServices";
import AuthContext from "../store/auth-context";
import { Row, Col } from "react-bootstrap";
import { FaUserPlus } from "react-icons/fa";
import LoadingSpinner from "../components/UI/LoadingSpinner";

const UserAdminPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [currentPageNumber, setcurrentPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalPages, setTotalPages] = useState(); 
  const [modalData, setModalData] = useState({
    titleText: "",
    text: "",
    buttonText: "",
    showActionButton: false,
  });
  const [doOnOk, setDoOnOk] = useState();

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const ToNewUserPage = () => {
    const url = "/CreateUser";
    navigate(url);
  };

  const GetUsersData = async (pageNumber: number, pageSize: number) => {
    setIsLoading(true);
    setPageSize(pageSize); 
    setcurrentPageNumber(pageNumber);
    var response = await GetUsersList(pageNumber, pageSize, authCtx.token);
    if (response.success) {
      const newDataArray = [];

      for (const element of response.data.data) {   
        let newUser = {
          ...element,
        };
        newDataArray.push(newUser);     
      }

      setData(newDataArray);
      setTotalRecords(response.data.total);
      setTotalPages(response.data.totalPages); 
    } else {
      setModalData((prevSate) => ({
        ...prevSate,
        titletext: "Failure",
        text: "Failed To Get Data",
      }));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetUsersData(1, 20);
  }, []);

  return (
    <Fragment>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Row className="align-items-center justify-content-center">
          <Col md={9} lg={9} xl={9}>
            <h2 className="text-center">User Admin</h2>
            <div className="mb-3 pull-right">
              <button
                className="btn btn-primary btn-sm"
                onClick={ToNewUserPage}
              >
                <FaUserPlus /> Create New User
              </button>
            </div>
            <UserTable
              data={data}
              currentPage={currentPageNumber}
              totalPages={totalPages}
              pageSize={pageSize}
              total={totalRecords}
              refresh={GetUsersData}
            />

            <ConfirmationModal
              show={showConfirmation}
              titleText={modalData.titleText}
              text={modalData.text}
              showActionButton={modalData.showActionButton}
              buttonText={modalData.buttonText}
              handleClose={handleClose}
              handleOk={doOnOk}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default UserAdminPage;
