import { User } from "../Interfaces/IUser";
import config from "../store/app.config";

const baseUrl = config['API_BASE_URL']

export const GetUserById = async (id: string , token: string) =>{

    try
    {
        const url = `${baseUrl}api/user/${id}`
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "text/html",
            })
        }); 

        if (await response.ok){
            return {"success": true , "data":await response.json()}; 
        } else {
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const GetUsersList = async(pageNum: number, pagesize: number, token: string)=>
{
    try
    {
        const url = `${baseUrl}api/user/` 
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "x-pagenumber": pageNum.toString(), 
                "x-pagesize": pagesize.toString(), 
               
            })
        }); 
 
        if (await response.ok){     
            return {"success": true , "data":await response.json()}; 
        }else{
            let rep = await response.text
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const UpdateUser = async(user: User, token: string)=>
{
    const updateRequest = {
        "organisation": user.organisation,
        "active": user.active,
        "termsAccepted": user.termsAccepted,
        "admin": user.admin
    }

    try
    {
        const url = `${baseUrl}api/user/${user.id}`
        const response = await fetch(url, {
            method: "PATCH", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(updateRequest),
        }); 

        if (await response.ok){
            return {"success": true, "data": "Successs"}
        } else {
            return {"error": true, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"error": true, "data": error}
    }
}

export const ResetUserPassword = async(userId: string, token: string)=>
{
    try
    {
        const url = `${baseUrl}api/user/userReset/${userId}`
        const response = await fetch(url, {
            method: "PATCH", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        }); 

        if (await response.ok){
            return {"success": true, "data": "Successs"}
        } else {
            return {"error": true, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"error": true, "data": error}
    }
}

export const CreateUser =async (user : User, token: string)=>
{
    const createRequest = {
        "organisation": user.organisation,
        "emailAddress": user.emailAddress,
        "active": true,
        "termsAccepted": false,
        "admin": user.admin
    }

    try
    {
        const url = `${baseUrl}api/user/`
        const response = await fetch(url, {
            method: "POST", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(createRequest),
        }); 

        if (await response.ok){
            return {"success": true, "data": "Successs"}
        } else {
            return {"error": true, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"error": true, "data": error}
    }
}