import { Fragment, useContext } from "react";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import AuthContext from "../../store/auth-context";

const Layout = (props: any) => {
  const authCtx = useContext(AuthContext);
  return (
    <Fragment>
      {authCtx.isLoggedIn && <Header />}

      <main className="mt-5">
        <Container>{props.children}</Container>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
