import { useState, useContext, useRef } from "react";
import * as XLSX from "xlsx";
import { Sim } from "../../Interfaces/ISim";
import { UploadError } from "../../Interfaces/UploadError";
import AuthContext from "../../store/auth-context";
import { CreateSim, ValidateIddValue } from "../../Services/SimService";
import { lowerCaseKeys } from "../../Services/Utilities";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../UI//ConfirmationModal";
import { Row, Col, Button, Alert } from "react-bootstrap";

type SimsUploadProps = {
  userId: string;
  setIsLoading(isloading: boolean): any;
  userSims: Sim[];
};

const SimUpload = (props: SimsUploadProps) => {
  const authCtx = useContext(AuthContext);
  const [uploadedData, setUploadedData] = useState<Sim[]>();
  const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
  const [modalData, setModalData] = useState<ConfirmationModalProps>({
    show: false,
    titleText: "",
    text: "",
    buttonText: "",
    handleOk: null,
    handleClose: null,
    showActionButton: false,
  });

  const ModalClose = () => {
    setModalData({
      show: false,
      titleText: "",
      text: "",
      buttonText: "",
      handleOk: null,
      handleClose: null,
      showActionButton: false,
    });
  };

  const UploadNewData = async (newData: Sim[]) => {
   
    let allCreated = true;
    const token = authCtx.token;

    for (var sim of newData) {
      sim.idd = ValidateIddValue(sim.idd);
      const response = await CreateSim(sim, token, props.userId);
      if (!response.success) {
        allCreated = false;
      }
    }

    if (allCreated) {
      window.location.reload();
    } else {
      setModalData({
        show: true,
        titleText: "Failure",
        text: "Failed To Uplaod Data",
        buttonText: "",
        handleOk: null,
        handleClose: () => {
          ModalClose();
        },
        showActionButton: false,
      });
    }
  };

  const CheckData = (data: Sim[]): UploadError[] => {
    let errors: UploadError[] = [];
    let checked: Sim[] = [];

    data.forEach((sim: Sim) => {
      if (!sim.serialNo || sim.serialNo.length == 0) {
        let newError: UploadError = {
          serialNo: "",
          errorMessage: sim.telNumber + " missing serial number",
        };
        errors.push(newError);
      }

      if (!sim.telNumber || sim.telNumber.length == 0) {
        let newError: UploadError = {
          serialNo: sim.serialNo,
          errorMessage: sim.serialNo + " missing telephone number",
        };
        errors.push(newError);
      }
      if (!sim.tariffName) {
        let newError: UploadError = {
          serialNo: sim.serialNo,
          errorMessage: sim.serialNo + " missing tariff",
        };
        errors.push(newError);
      } 
      let serialNoExists = props.userSims.find(
        (x) => x.serialNo == sim.serialNo
      );
      if (serialNoExists) {
        let newError: UploadError = {
          serialNo: sim.serialNo,
          errorMessage: sim.serialNo + " already attached to user",
        };
        errors.push(newError);
      }
      let serialNumAlreadyInUpload = checked.find(
        (x) => x.serialNo == sim.serialNo
      );
      if (serialNumAlreadyInUpload) {
        let newError: UploadError = {
          serialNo: sim.serialNo,
          errorMessage: sim.serialNo + " duplicated in upload",
        };
        errors.push(newError);
      }

      checked.push(sim);
    });

    return errors;
  };

  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const OnFileUpload = async (e) => {
    setUploadErrors([]);
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const ioData: Sim[] = XLSX.utils.sheet_to_json<Sim>(ws, { raw: false });
        const data = lowerCaseKeys(ioData);
        setUploadedData(data);
        setUploadErrors([]);
        let errorList = CheckData(data);
        
        if (errorList.length > 0) {
          setUploadErrors(errorList);
        } else {
          UploadNewData(data);
        }
      };

      reader.readAsBinaryString(file);
    } catch (error) {
      console.log(error);
    }
    inputRef.current?.files &&
      setUploadedFileName(inputRef.current.files[0].name);
  };

  return (
    <>
      <Row className="align-items-center justify-content-center">
        <Col md={8}>
          <h4 className="mt-5 text-center">
            Select file to upload new SIMs data for user
          </h4>

          {uploadErrors.length > 0 && (
            <Alert variant="danger" className="excel-val">
              <Alert.Heading>
                Cannot Upload File Due To Following Errors:
              </Alert.Heading>
              {uploadErrors.length > 0 &&
                uploadErrors.map((error) => {
                  return (
                    <p>
                      Serial No: {error.serialNo} Error Message:{" "}
                      {error.errorMessage}
                    </p>
                  );
                })}
            </Alert>
          )}

          <div className="my-5 text-center">
            <label className="mx-3">Choose file:</label>
            <input
              ref={inputRef}
              className="d-none"
              type="file"
              id="fileUpload"
              onChange={OnFileUpload}
            />
            <button
              onClick={handleUpload}
              className={`btn ${
                uploadedFileName ? "btn-success" : "btn-primary"
              }`}
            >
              {uploadedFileName ? uploadedFileName : "Upload"}
            </button>
          </div>

          <ConfirmationModal
            show={modalData.show}
            titleText={modalData.titleText}
            text={modalData.text}
            buttonText={modalData.buttonText}
            handleOk={modalData.handleOk}
            handleClose={modalData.handleClose}
            showActionButton={modalData.showActionButton}
          />
        </Col>
      </Row>
    </>
  );
};

export default SimUpload;
