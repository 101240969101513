import { Fragment, useContext, useEffect, useState } from "react";
import { GetSimsByUserId, UpdateSim } from "../Services/SimService";
import { useParams } from "react-router-dom";
import { User } from "../Interfaces/IUser";
import { Sim } from "../Interfaces/ISim";
import { DocumentInfo } from "../Interfaces/IDocumentInfo";
import AuthContext from "../store/auth-context";
import OrganisationSimsTable from "../components/OrganisationSimsPage/OrganisationSimTable";
import { Row, Col, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { GetUserById } from "../Services/UserServices";
import { UploadError } from "../Interfaces/UploadError";
import ConfirmationModal, { ConfirmationModalProps } from "../components/UI/ConfirmationModal";
import { HiDownload, HiUpload } from "react-icons/hi";
import IDInfo from "../components/OrganisationSimsPage/IDInfo";
import { GetUserDocumentsData } from "../Services/DocumentService";
import { BsFillSimFill, BsFillQuestionCircleFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";

const defaultConfirmation: ConfirmationModalProps = {
  show: false,
  titleText: "",
  text: "",
  buttonText: "",
  handleOk: null,
  handleClose: null,
  showActionButton: false,
};

const defaultDocInfo: DocumentInfo = {
  firstName: "",
  lastName: "",
  refName: "",
  expirationDate: undefined,
  documentUploaded: false,
  countryOfIssue: "",
};

const OrganisationSimsPage = () => {
  const authCtx = useContext(AuthContext);
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const [simsData, setSimsData] = useState([]);
  let { userId } = useParams();
  const [uploadedData, setUploadedData] = useState<Sim[]>([]);
  const [changedData, setChangedData] = useState<Sim[]>();
  const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
  const [modalData, setModalData] = useState<ConfirmationModalProps>(defaultConfirmation);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo>(defaultDocInfo);

  const OnModalClose = () => {
    setModalData(defaultConfirmation);
  };

  const GetUserSims = async (pageNum: number, pageSize: number) => {
    setIsLoading(true);

    const response = await GetSimsByUserId(userId, pageNum, pageSize, authCtx.token);

    if (response.success) {
      let dataArray: Sim[] = [];
      response.data.data.forEach((element) => {
        dataArray.push(element);
      });

      setSimsData(dataArray);
      setTotal(response.data.total);
      setTotalPages(response.data.totalPages)
      setPageNumber(pageNum);
      setPageSize(pageSize);
      setTotalPages(response.data.totalPages);
    } else {
      console.log("error");
    }

    setIsLoading(false);
  };

  const GetUserDocData = async () => {
    const docInforespose = await GetUserDocumentsData(userId, authCtx.token);
    if (docInforespose.success) {
      let returnedData = docInforespose.data;
      let newDocInfo: DocumentInfo = {
        firstName: returnedData.firstName,
        lastName: returnedData.lastName,
        expirationDate: returnedData.expirationDate,
        refName: returnedData.referenceNumber,
        countryOfIssue: returnedData.countryOfIssue,
        documentUploaded: true,
      };

      setDocumentInfo(newDocInfo);
    }
  };

  const downloadSpreadSheet = async () => {
    const userResponse = await GetUserById(userId, authCtx.token);
    let fileName: string = `sims.xlsx`;
    if (userResponse.success) {
      fileName = userResponse.data.organisation + ".xlsx";
    }

    let spreadSheetData = simsData;
    spreadSheetData.forEach((element) => {
      element.idd = element.idd ? "Yes" : "No";
      element.validated = element.validated ? "Yes" : "No";
    });

    const worksheet = XLSX.utils.json_to_sheet(spreadSheetData);

    worksheet["!cols"] = [];
    worksheet["!cols"][0] = { hidden: true };
    worksheet["!cols"][4] = { hidden: true };

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  useEffect(() => {
    GetUserSims(1, 20);
    GetUserDocData();
  }, [userId]);

  const UploadNewData = async (data: Sim[]) => {
    let allDataUploaded = true;
    for (var sim of data) {
      let token = authCtx.token;
      let serverSim: Sim = simsData.find((x) => x.id === sim.id || x.serialNo === sim.serialNo);
      if (serverSim.validated) {
        continue;
      }
      if (serverSim) {
        serverSim.referenceNumber = sim.referenceNumber ? sim.referenceNumber : null;

        const response = await UpdateSim(serverSim, token, userId);

        if (await !response.success) {
          allDataUploaded = false;
        }
      }
    }

    if (allDataUploaded) {
      GetUserSims(1, 10);
    } else {
      setModalData({
        show: true,
        titleText: "Failure",
        text: "Failed To Upload Data",
        buttonText: "",
        handleOk: null,
        handleClose: { OnModalClose },
        showActionButton: false,
      });
    }
  };

  const OnFileUpload = (e) => {
    setIsLoading(true);
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: Sim[] = XLSX.utils.sheet_to_json(ws, { raw: false });
        setUploadedData(data);
        setUploadErrors([]);
        let errorList = checkData(data);
        if (errorList.length > 0) {
          setUploadErrors(errorList);

          setIsLoading(false);
        } else {
          UploadNewData(data);
        }
      };

      reader.readAsBinaryString(file);
      e.target.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  const SaveData = async () => {
    await UploadNewData(changedData);

    window.location.reload();
  };

  const checkData = (data: Sim[]): UploadError[] => {
    let errorList: UploadError[] = [];
    data.forEach((sim) => {
      if (!sim.id && !sim.serialNo) {
        errorList.push({
          serialNo: sim.serialNo ? sim.serialNo : "",
          errorMessage: "no serial Number or id",
        });
        return;
      }

      let serverSim: Sim = simsData.find((x) => x.id === sim.id || x.serialNo === sim.serialNo);

      if ((!sim.activationDate && sim.deactivationDate) || (sim.activationDate && !sim.deactivationDate)) {
        errorList.push({
          serialNo: sim.serialNo,
          errorMessage: "Cannot have an arrival or departure date if other does not exist",
        });
      }

      if (sim.activationDate) {
        try {
          const arrDate = new Date(sim.activationDate);
        } catch (error) {
          errorList.push({
            serialNo: sim.serialNo,
            errorMessage: "Arrival date not valid Or in wrong format",
          });
        }
      }

      if (sim.deactivationDate) {
        try {
          const depDate = new Date(sim.deactivationDate);
        } catch (error) {
          errorList.push({
            serialNo: sim.serialNo,
            errorMessage: "Depature date not valid or in wrong format",
          });
        }
      }
    });

    return errorList;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <IDInfo docInfo={documentInfo}></IDInfo>
      <Row className="align-items-center justify-content-center">
        <Col md={12}>
          <Row>
            <Col>
              <div className="header-split">
                <h4>
                  <BsFillSimFill />
                  SIM List
                </h4>
                <div className="push">
                  {simsData.length > 0 && (
                    <button onClick={downloadSpreadSheet} className="btn btn-primary btn-sm">
                      <HiDownload /> Download SIM list
                    </button>
                  )}
                  {/* <label htmlFor="fileUpload"> Upload File</label>
                <input type="file" id="fileUpload" onChange={OnFileUpload}></input> */}

                  <>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        document.getElementById("fileUpload").click();
                      }}
                    >
                      <HiUpload />
                      Batch Upload Refs
                    </button>
                    <input
                      id="fileUpload"
                      type="file"
                      accept="xlsx, xls"
                      multiple={false}
                      onSubmit={OnFileUpload}
                      onChange={OnFileUpload}
                      style={{ display: "none" }}
                    />
                    <button onClick={handleShow} className="btn btn-default btn-sm ">
                      <BsFillQuestionCircleFill /> Help
                    </button>
                  </>
                </div>
              </div>
            </Col>
          </Row>

          {!isLoading && (
            <Fragment>
              <Row className="justify-content-md-center mt-3">
                <Col>
                  <Fragment>
                    <OrganisationSimsTable
                      data={simsData}
                      isLoading={setIsLoading}
                      userId={userId}
                      setChangedData={setChangedData}
                      pageSize={pageSize}
                      pageNumber={pageNumber}
                      totalPages={totalPages}
                      total={total}
                      refresh={GetUserSims}
                    />

                    <div className="d-grid justify-content-center gap-2 d-md-flex">
                      <Button
                        className="btn btn-primary mt-3"
                        disabled={!changedData || changedData.length === 0}
                        onClick={SaveData}
                      >
                        Save changes
                      </Button>
                    </div>
                  </Fragment>
                </Col>
              </Row>
            </Fragment>
          )}
        </Col>
      </Row>
      <ConfirmationModal
        show={modalData.show}
        titleText={modalData.titleText}
        text={modalData.text}
        buttonText={modalData.buttonText}
        handleOk={modalData.handleOk}
        handleClose={modalData.handleClose}
        showActionButton={modalData.showActionButton}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To bulk update your SIM References download the SIM spreadsheet template using the download button, update the
          reference column then upload the spreadsheet using the Batch Upload Refs button.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default OrganisationSimsPage;
