import { Sim } from "../Interfaces/ISim";
import config from "../store/app.config";

const baseUrl = config['API_BASE_URL']; 

export const GetSimsByUserId = async (id: string, pageNum: number, pageSize: number , token: string) =>
{
    try
    {
        const url = `${baseUrl}api/sim/user/${id}`
        const response = await fetch(url, {
            method: "GET", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "x-pagenumber": pageNum.toString(), 
                "x-pagesize" : pageSize.toString()
            })
        }); 

        if (response.ok){
            return {"success": true, "data":await response.json()}; 
        } else {
           if ( response.status == 400){
            return {"success": false, "data": "Not Found"}
           }
            return {"success": false, "data": "Failure"}
        }
    }
    catch(error)
    {
        return {"success": false, "data": error}
    }
}

export const UpdateSim = async(simToUpdate: Sim, token:string, userId: string)=>
{
    try
    {
        const request = {
            "userId": userId,
            "serialNo": simToUpdate.serialNo,
            "telNumber":simToUpdate.telNumber,
            "tariffId": simToUpdate.tariffId,
            "idd": simToUpdate.idd,
            "activationDate": simToUpdate.activationDate,
            "deactivationDate": simToUpdate.deactivationDate,
            "referenceNumber": simToUpdate.referenceNumber, 
            "validated": !simToUpdate.validated ? false : simToUpdate.validated
        }

        const url = `${baseUrl}/api/sim/${simToUpdate.id}`
       
        const response = await fetch(url, {
            method: "PATCH", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
               
            }), body: JSON.stringify(request),
        }); 

        if (response.ok){
            return {"success": true, "data": ""}; 
        } else {
            return {"success": false, "data": "Failure"}
        }
    } catch(error){
        return {"success": false, "data": error}
    }
}

export const CreateSim =async(sim: Sim, token: string, userId: string) =>
{
    try
    {
        const request = {
            "userId": userId, 
            "serialNo": sim.serialNo, 
            "telNumber":sim.telNumber, 
            "tariffId": sim.tariffId, 
            "idd": sim.idd,        
        }

        const url = `${baseUrl}api/sim/`; 
       
        const response = await fetch(url, {
            method: "POST", 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",     
            }), body: JSON.stringify(request),
        }); 

        if (await response.ok){
            return {"success": true, "data": ""}; 
        } else { 
            return {"success": false, "data": "Failure"}
        }
    } catch(error){
        return {"success": false, "data": error}
    }
}

export const ValidateIddValue = (iddValue) : boolean=>
{
    const trueValues : string[] = ["YES", "TRUE", "Y"]; 

    if (trueValues.includes(iddValue.toUpperCase()))
    {
        return true; 
    }

    return false; 
}