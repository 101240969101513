import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import { Form } from "react-bootstrap";
import { useState, useContext, Fragment } from "react";
import * as Yup from "yup";
import { Card, Row, Col, Button } from "react-bootstrap";
import { User } from "../Interfaces/IUser";
import { CreateUser } from "../Services/UserServices";
import AuthContext from "../store/auth-context";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import Toolbar from "../components/UI/Toolbar";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

interface CreateUserFormValues {
  Organisation: string;
  EmailAddress: string;
  Admin: boolean;
}

const CreateUserPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const initialValues: CreateUserFormValues = {
    Organisation: "",
    EmailAddress: "",
    Admin: false,
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({
    titleText: "Create New User",
    text: "",
    buttonText: "",
    showActionButton: false,
  });

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const NewUserCreate = async (values) => {
    let newUser: User = {
      organisation: values.Organisation,
      emailAddress: values.EmailAddress,
      admin: values.Admin,
      active: true,
      id: "",
      termsAccepted: false,
    };

    const result = await CreateUser(newUser, authCtx.token);
    if (result.success) {
      setConfirmationProps((prevState) => ({
        ...prevState,
        text: "New User Created",
      }));
      setShowConfirmation(true);
    } else {
      setConfirmationProps((prevState) => ({
        ...prevState,
        text: "Failed To Create New User",
      }));
      setShowConfirmation(true);
    }
  };

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center">
        <Col md={5} lg={5} xl={5}>
          <Toolbar buttons={[]} title="Create New User"></Toolbar>

          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              NewUserCreate(values);
            }}
            validationSchema={Yup.object().shape({
              Organisation: Yup.string()
                .required("Enter the Organisation.")
                .min(2, "Organisation name too short."),
              EmailAddress: Yup.string()
                .email("Email format is invalid.")
                .required("Enter your Email address."),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card className="my-4 p-5">
                  <Form.Group className="mb-3">
                    <Form.Label>Organisation</Form.Label>
                    <Form.Control
                      name="Organisation"
                      type="text"
                      placeholder="Enter Organisation"
                      value={values.Organisation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.Organisation && touched.Organisation && "error"
                      }
                      tabIndex={1}
                    ></Form.Control>
                    {errors.Organisation && touched.Organisation && (
                      <div className="input-feedback mt-1">
                        {errors.Organisation}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      name="EmailAddress"
                      type="text"
                      placeholder="Enter Email"
                      value={values.EmailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.EmailAddress && touched.EmailAddress && "error"
                      }
                      tabIndex={1}
                    ></Form.Control>
                  </Form.Group>
                  {errors.EmailAddress && touched.EmailAddress && (
                    <div className="input-feedback">{errors.EmailAddress}</div>
                  )}
                  <Form.Group>
                    <Form.Check
                      name="Admin"
                      type="checkbox"
                      label="Admin User"
                      placeholder="Is Admin"
                      onChange={handleChange}
                    ></Form.Check>
                  </Form.Group>
                </Card>
                <div className="d-grid justify-content-center gap-2 d-md-flex">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      navigate("/UserAdmin");
                    }}
                  >
                    <IoChevronBackOutline /> Return to User Admin
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    Create New User
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <ConfirmationModal
        show={showConfirmation}
        titleText={confirmationProps.titleText}
        text={confirmationProps.text}
        buttonText=""
        handleOk={null}
        handleClose={handleClose}
        showActionButton={false}
      />
    </Fragment>
  );
};

export default CreateUserPage;
