import { Fragment, ReactNode } from "react";
import ToolbarButton, { ToolbarButtonProps } from "./ToolbarButton";

type ToolbarProps = {
  buttons: ToolbarButtonProps[];
  title: string;
  children?: ReactNode;
};

const Toolbar = (props: ToolbarProps) => {
  const MakeButton = (data) => {
    return <ToolbarButton disabled={data.disabled} onClick={data.onClick} icon={data.icon} label={data.label} />;
  };
  return (
    <Fragment>
      <h4 className="text-center">{props.title}</h4>
      {props.children}
    </Fragment>
  );
};

export default Toolbar;
