import {  Spinner } from "react-bootstrap";

const LoadingSpinner = ()=>{

    return (
        <div className="text-center">
              <div className="loading">
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                <span>Loading...</span>
              </div>
            </div>
    )
}

export default LoadingSpinner; 