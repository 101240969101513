import { useState, useContext, Fragment } from "react";
import AuthContext from "../../store/auth-context";
import Terms from "../Terms";
import classes from "../../styles/partials/layout/Footer.module.scss";

function Footer() {
  const authCtx = useContext(AuthContext);
  const [showTerms, setShowTerms] = useState(false);
  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);
  return (
    <div className={classes.footer}>
      <div>
        <p className="small">
          &copy; {new Date().getFullYear()} Cellhire Ltd
        </p>
      </div>
    </div>
  );
}

export default Footer;
