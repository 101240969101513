import { Sim } from "../../Interfaces/ISim";
import { useTable, Column, useRowSelect } from "react-table";
import { useMemo, useEffect, useState } from "react";
import InderterminateCheckbox from "../UI/InderterminateCheckbox";
import Pageination from "../UI/Pagination";
import {TiTick} from "react-icons/ti"; 
import {ImCross} from "react-icons/im"; 
import DatePicker from "react-datepicker";

type UserSimsTableProps = {
  setIsloading(isloading: boolean): any;
  userSims: any[];
  setSelectedSimIds(simIds: string[]): any;
  setChangedData(sims:Sim[]): any; 
  currentPageNum: number;
  totalPages: number,
  pageSize: number;
  total: number;
  refresh(pageNum: number, pageSize: number): any;
};

const UserSimsTable = (props: UserSimsTableProps) => {
  let ValidatedRowIndexs = [];
  const [updatedRows, setUpdatedRows] = useState([]);
  
  const onRowSelect = (e) => {
    let selectedSimIds = [];
    e.forEach((element) => {
      let id = element.values.id;
      selectedSimIds.push(id);
    });

    props.setSelectedSimIds(selectedSimIds);
  };

  const columns: Column<Sim>[] = useMemo(
    () => [
      {
        Header: "Id",
        id: "id",
        accessor: "id",
      },
      {
        id:"serialNo",
        Header: "Serial No.",
        accessor: "serialNo",
      },
      {
        id:"telNumber", 
        Header: "Tel Number",
        accessor: "telNumber",
      },
      {
        id: "tariff", 
        Header: "Tariff",
        accessor: "tariffName",
      },
      {
        id: "idd",
        Header: "IDD",
        accessor: (d) => d.idd.toString(),
      },
      {
        id: "activationDate",
        Header: "Arrival Date",
        accessor: (d) => {
          if (!d.activationDate) return "";
          const arrdate = new Date(d.activationDate.toString());
          return arrdate;
        },
      },
      {
        id: "deactivationDate",
        Header: "Depature Date",
        accessor: (d) => {
          if (!d.deactivationDate) return "";
          const depDate = new Date(d.deactivationDate.toString());
          return depDate;
        },
      },
      {
        id: "ref",
        Header: "Reference",
        accessor: "referenceNumber",
      },
      {
        id: "validated",
        Header: "Validated",
        accessor: "validated",
        Cell: (e)=>
          e.value ? (<><TiTick/></>)
          : (<><ImCross/></>)
      },
    ],
    []
  );

  const UpdateMyData = (index, id, value) => {
    const row = props.userSims[index];
    const updated = updatedRows;
    const alreadyUpdated = updated.find((x) => x.id == row.id);
    if (alreadyUpdated) {
      alreadyUpdated[id] = value;
      return;
    }

    row[id] = value;
    updated.push(row);
    setUpdatedRows(updated);
    props.setChangedData(updated);
  };


  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onDateChange = (e) => {
      setValue(e);
      UpdateMyData(index, id, e);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      UpdateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    let indexRows = ValidatedRowIndexs;

    if(id == "idd"){
      if (ValidatedRowIndexs.includes(index)) {
        return <input value={value} readOnly onBlur={onBlur} />;
      } else {
        return <select value={value} onChange={onChange} onBlur={onBlur} >
          <option value="true">True</option>
          <option value="false">False</option>
        </select>;
      }
    }
    const editableColumns: string[] = ["referenceNumber", "tariff", "telNumber", "serialNo"]
    // Check to make sure not all columns are editable
    if (editableColumns.includes(id)) {
      if (ValidatedRowIndexs.includes(index)) {
        return <input value={value} readOnly onBlur={onBlur} />;
      } else {
        return <input value={value} onChange={onChange} onBlur={onBlur} />;
      }
    }
    if (id == "arrivalDate" || id == "departureDate") {
      if (ValidatedRowIndexs.includes(index)) {
        return <input value={value.toString()} readOnly />;
      } else {
        return (
          <div>
            <DatePicker selected={value} onChange={onDateChange} />
          </div>
        );
      }
    }
    return value;
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: columns,
      data: props.userSims,
      defaultColumn,
      initialState: {
        hiddenColumns: ["id"],
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <InderterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <InderterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelect(selectedFlatRows);
  }, [selectedRowIds]);

  return (
    <div className="table-group">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pageination
        currentPage={props.currentPageNum}
        pageSize={props.pageSize}
        totalpages={props.totalPages}
        total={props.total}
        onChange={props.refresh}
      />
    </div>
  );
};

export default UserSimsTable;
