import { Fragment } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import LoginForm from "../components/Login/LoginForm";

const AuthPage = () => {
  return (
    <Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <Card.Body className="p-4">
                  <LoginForm />
                  <div className="p-2 mt-4"></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default AuthPage;
