import { useState, useContext } from "react";
import { Container, Stack, Form, Button, Modal } from "react-bootstrap";
import { User } from "../../Interfaces/IUser";
import { UpdateUser } from "../../Services/UserServices";
import AuthContext from "../../store/auth-context";
import LoadingSpinner from "../UI/LoadingSpinner";

interface acceptProps {
  user: User;
  userId: string;
  termsAccepted(accepted: boolean): any;
}

const AcceptTerms: React.FC<acceptProps> = (props: acceptProps) => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const AcceptTerms = async () => {
    setIsLoading(true);
    props.user.termsAccepted = true;
    const result = await UpdateUser(props.user, authCtx.token);
    if (result.success) {
      props.termsAccepted(true);
    }

    setIsLoading(false);
  };
  return (
    <>
      <Container>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Stack>
            <Modal size="lg" show centered>
              <Modal.Header>
                <Modal.Title>Terms & Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <div className="d-grid justify-content-center gap-2 d-md-flex">
                    <div>
                      <p>
                      Customers who wish to use Cellhire mobile telecommunications services for large events are
                      able to access the SIM details through this website. The details provided are used to activate
                      and provision SIMs with the network on the appropriate dates in an automated manner. Therefore
                      it is incumbent on the customer to ensure the data provided is as complete and accurate as
                      possible
                      </p>
                      <p>
                      In accordance with the UK Data Protection Act 1998 (DPA) and the EU General Data Protection
                      Regulation (GDPR) Cellhire confirms that any information collected by Cellhire for the purposes
                      of SIM activation will be treated in the following manner:                        

                        <ol>
                          <li>Obtained by secure methods using a secure portal or other appropriate method</li>
                          <li>Protected securely within the Cellhire network</li>
                          <li>Viewed only by personnel who need to see this information for processing</li>
                          <li>
                            Only used for the intended purpose of processing the order and ensuring the activation of
                            the service
                          </li>
                          <li>Be deleted from the system once processing is complete and no longer required</li>
                          <li>Transferred to the mobile network operator provider in a secure manner on request from the network</li>
                        </ol>
                      </p>
                      <p>
                      If any breach of the information occurs during the collection, retention or transfer of the
                      personal information, Cellhire will, in accordance with UK Data Protection legislation, report
                      this to the UK Information Commission Office (ICO) and advise any affected customers and the
                      mobile network operator. Any such incident would be logged and investigated to international
                      standards.

                      </p>
                      <p>
                      Any personal information provided through this portal if transmitted to a third party would be done securely using 256 bit SSL
                      encryption. Cellhire Account Managers may sometimes need to access records to aid in data
                      verification or validation. The storage period will be for no more than 3 months after the
                      duration of the rental agreement. By accepting these terms and conditions you are authorising
                      Cellhire to transmit data if requested by the relevant authorities from this website in a secure manner to our mobile network partner.

                      </p>
                      <div className="text-center">
                        <Button className="btn btn-primary mt-5" onClick={AcceptTerms}>
                          Accept Terms &amp; Conditions
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default AcceptTerms;
