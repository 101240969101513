import { useMemo, useState, useEffect, useContext } from "react";
import { useTable, Column } from "react-table";
import { Sim } from "../../Interfaces/ISim";
import { UpdateSim } from "../../Services/SimService";
import AuthContext from "../../store/auth-context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../UI/Pagination";
import { Card } from "react-bootstrap";

type OrganisationSimsTableProps = {
  data: any[];
  isLoading(loading: boolean): any;
  userId: string;
  setChangedData(newData: Sim[]): any;
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  total: number;
  refresh(pageNum: number, pageSize: number): any;
};

const OrganisationSimsTable = (props: OrganisationSimsTableProps) => {
  const authCtx = useContext(AuthContext);
  const [updatedRows, setUpdatedRows] = useState([]);
  let ValidatedRowIndexs = [];

  const PaginationChange = (pageNumber: number, pageSize: number) => {
    props.refresh(pageNumber, pageSize);
  };

  const columns: Column<Sim>[] = useMemo(
    () => [
      { Header: "Serial Number", accessor: "serialNo" },
      {
        Header: "Tel No",
        accessor: "telNumber",
      },
      {
        Header: "Tariff",
        accessor: "tariffName",
      },
      {
        id: "idd",
        Header: "International",
        accessor: (d) => {
          if(d.idd) return "Yes";
          return "No"
        },
      },
      {
        id: "activationDate",
        Header: "Activation Date",
        accessor: (d) => {
          if (!d.activationDate) return "";
          const arrdate = new Date(d.activationDate.toString());
          return arrdate.toDateString();
        },
      },
      {
        id: "deactivationDate",
        Header: "Deactivation Date",
        accessor: (d) => {
          if (!d.deactivationDate) return "";
          const depDate = new Date(d.deactivationDate.toString());
          return depDate.toDateString();
        },
      },
      {
        id: "referenceNumber",
        Header: "Reference",
        accessor: "referenceNumber",
      },
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "userId",
        accessor: "userId",
      },
      {
        id: "validated",
        Header: "Validated",
        accessor: (d) => {
          if(d.validated) return "Yes";
          return "No"
        },
      },
    ],
    []
  );

  const UpdateMyData = (index, id, value) => {
    const row = props.data[index];
    const updated = updatedRows;
    const alreadyUpdated = updated.find((x) => x.id == row.id);
    if (alreadyUpdated) {
      alreadyUpdated[id] = value;
      return;
    }

    row[id] = value;
    updated.push(row);
    setUpdatedRows(updated);
    props.setChangedData(updated);
  };

  // Create an editable cell renderer
  const EditableCell = ({ value: initialValue, row: { index }, column: { id } }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onDateChange = (e) => {
      setValue(e);
      UpdateMyData(index, id, e);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      UpdateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    // Check to make sure not all columns are editable
    if (id == "referenceNumber") {
      if (ValidatedRowIndexs.includes(index)) {
        return <input value={value} readOnly onBlur={onBlur} />;
      } else {
        return <input value={value} onChange={onChange} onBlur={onBlur} />;
      }
    }
    if (id == "arrivalDate" || id == "departureDate") {
      return <input value={value.toString()} readOnly />;
    }
    return value;
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: props.data,
    defaultColumn,

    initialState: {
      hiddenColumns: ["id", "userId"],
    },
  });

  return (
    <Card>
      <div className="table-group">
        {(ValidatedRowIndexs = [])}
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              if (row.values.validated) {
                ValidatedRowIndexs.push(row.index);
              }
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          currentPage={props.pageNumber}
          pageSize={props.pageSize}
          totalpages={props.totalPages}
          total={props.total}
          onChange={PaginationChange}
        />
      </div>
    </Card>
  );
};

export default OrganisationSimsTable;
