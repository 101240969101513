import { useParams } from "react-router-dom";
import { GetUserById, UpdateUser } from "../Services/UserServices";
import AuthContext from "../store/auth-context";
import { Fragment, useContext, useEffect, useState } from "react";
import { User } from "../Interfaces/IUser";
import { Formik } from "formik";
import { Card, Form, Button } from "react-bootstrap";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import ConfirmationModal from "../components/UI/ConfirmationModal";
import { Row, Col } from "react-bootstrap";
import Toolbar from "../components/UI/Toolbar";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

const EditUser = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  let { userId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<User>();
  const [organisation, setOrganisation] = useState("");
  const [admin, setAdmin] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({
    show: false,
    titleText: "",
    text: "",
    buttonText: "",
    showActionButton: false,
  });

  const handleModalClose = () => {
    setConfirmationProps({
      show: false,
      titleText: "",
      text: "",
      buttonText: "",
      showActionButton: false,
    });
  };
  const GetUserData = async () => {
    setIsLoading(true);
    const userDataResponse = await GetUserById(userId, authCtx.token);
    if (userDataResponse.success) {
      setUserData(userDataResponse.data);
      setOrganisation(userDataResponse.data.organisation);
      setAdmin(userDataResponse.data.admin);
    }

    setIsLoading(false);
  };

  const UpdateUserValues = async (values) => {
    let updatedUser: User = {
      id: userId,
      organisation: values.Organisation,
      termsAccepted: values.TermsAccepted,
      admin: values.IsAdmin,
      active: true,
      emailAddress: "",
    };

    const updateResponse = await UpdateUser(updatedUser, authCtx.token);
    if (updateResponse.success) {
      setConfirmationProps({
        show: true,
        titleText: "Success",
        text: "Updated Succeeded",
        buttonText: "",
        showActionButton: false,
      });
    } else {
      setConfirmationProps({
        show: true,
        titleText: "Failure",
        text: "Updated Failed",
        buttonText: "",
        showActionButton: false,
      });
    }
  };

  useEffect(() => {
    GetUserData();
  }, []);

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center">
        <Col md={8} lg={6} xl={5}>
          <Toolbar buttons={[]} title="Change Organisation"></Toolbar>

          {isLoading && <LoadingSpinner />}

          {!isLoading && (
            <Formik
              initialValues={{
                Organisation: organisation,
                IsAdmin: admin,
              }}
              onSubmit={(values, actions) => {
                UpdateUserValues(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Card className="my-4 p-5">
                    <Form.Group className="mb-3">
                      <Form.Label>Organisation</Form.Label>
                      <Form.Control
                        name="Organisation"
                        type="text"
                        onChange={handleChange}
                        value={values.Organisation}
                      ></Form.Control>
                    </Form.Group>
                  </Card>
                  <div className="d-grid justify-content-center gap-2 d-md-flex">
                    <Button
                      className="btn btn-secondary"
                      onClick={() => {
                        navigate("/UserAdmin");
                      }}
                    >
                      <IoChevronBackOutline /> Return to User Admin
                    </Button>
                    <button type="submit" className="btn btn-primary">
                      Update User
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          <ConfirmationModal
            show={confirmationProps.show}
            titleText={confirmationProps.titleText}
            text={confirmationProps.text}
            buttonText=""
            handleOk={null}
            handleClose={handleModalClose}
            showActionButton={false}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default EditUser;
