import Layout from "./components/UI/Layout";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import AuthContext from "./store/auth-context";
import AuthPage from "./pages/AuthPage";
import UserAdminPage from "./pages/UserAdminpage";
import OrganisationPage from "./pages/OrganisationPage";
import OrganisationSimsPage from "./pages/OrganisationsSimsPage";
import CreateUserPage from "./pages/CreateUserPage";
import DocsValidationPage from "./pages/DocValidationPage";
import EditUser from "./pages/EditUser";
import UserSimsPage from "./pages/UserSimsPage";

function App() {
  const authCtx = useContext(AuthContext);
  return (
    <div>
      <Layout>
        {!authCtx.isLoggedIn && (
          <Routes>
            <Route path="*" element={<AuthPage />} />
          </Routes>
        )}
        {authCtx.isLoggedIn && (
          <Routes>
            <Route path="/UserAdmin" element={<UserAdminPage />} />
            <Route path="/CreateUser" element={<CreateUserPage />} />
            <Route path="/EditUser/:userId" element={<EditUser />} />
            <Route path="/UserSims/:userId" element={<UserSimsPage />} />
            <Route
              path="/DocsValidate/:docId"
              element={<DocsValidationPage />}
            />
            <Route
              path="/Organisation/:userId"
              element={<OrganisationPage />}
            />
            <Route
              path="/OrganisationSims/:userId"
              element={<OrganisationSimsPage />}
            />
            <Route path="*" element={<OrganisationPage />} />
          </Routes>
        )}
      </Layout>
    </div>
  );
}

export default App;
