import { Card, Row, Col } from "react-bootstrap";
import { HiIdentification } from "react-icons/hi";
import { DocumentInfo } from "../../Interfaces/IDocumentInfo";

interface IDInfoProps {
  docInfo: DocumentInfo;
}

const IDInfo = (props: IDInfoProps) => {
  const jsonStringToDate = (dateString) => {
    const aDate = new Date(dateString.toString());
    return aDate.toDateString();
  };
  const { docInfo } = props;
  const issueDateString = docInfo.expirationDate ? jsonStringToDate(docInfo.expirationDate) : " ";
  const docUploaded = docInfo.documentUploaded ? "Yes" : "No";
  return (
    <>
      <h4>
        <HiIdentification />
        ID Information
      </h4>
      <Card className="mb-5 p-2">
        <Row>
          <Col>
            <div className="id-info">
              <span>
                <span>First Name: </span> {docInfo.firstName}
              </span>
              <span>
                <span>Last Name: </span> {docInfo.lastName}
              </span>
              <span>
                <span>Ref Name: </span> {docInfo.refName}
              </span>
              <span>
                <span>Country of Issue: </span> {docInfo.countryOfIssue}
              </span>
              <span>
                <span>Expiration Date: </span> {issueDateString}
              </span>
              <span>
                <span>Document Uploaded: </span>
                {docUploaded}
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default IDInfo;
