import React, { useState } from "react";
import jwt_decode from "jwt-decode";

export interface IAuthContext  {
    token: string, 
    decodedToken: IDecodedToken,
    isLoggedIn: boolean, 
    login : (token: string)=> void, 
    logout: () =>void, 
    tokenExpired: boolean
}

export interface IDecodedToken {
    id: string, 
    organisation: string, 
    emailAddress: string, 
    TermsAccepted: boolean, 
    Admin: boolean, 
    exp: number
}

const AuthContext = React.createContext({
    token: null, 
    decodedToken: null, 
    isLoggedIn: false, 
    login: (token: string) => {}, 
    logout: ()=>{}    
})

export const AuthContextProvider =(props: any)=>{

  const initialToken =localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  const [tokenExpired, setTokenExpired] = useState(false);
  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const timeoutHandler = () => {
    setToken('');
    localStorage.removeItem("token");
    localStorage.removeItem("decodedToken")
    setTokenExpired(true);
  };

  const loginHandler = (token : string) => {
    setToken(token);
    localStorage.setItem("token", token);
    setTokenExpired(false);
  };

  let decodedToken: IDecodedToken ={
      id: '', 
      organisation: '', 
      emailAddress: '', 
      TermsAccepted: false, 
      Admin: false, 
      exp: 0
  } 

  if (token) {
    decodedToken = jwt_decode(token); 

    let tokenExpiryDate = new Date(decodedToken.exp * 1000);

    const remainingTime = tokenExpiryDate.getTime() - Date.now();

    if (tokenExpiryDate.getTime() < Date.now()) {
      timeoutHandler();
    } else {
      setTimeout(timeoutHandler, remainingTime);
    }
  }

  const contextValue: IAuthContext ={
      token: token, 
      decodedToken: decodedToken, 
      isLoggedIn: userIsLoggedIn, 
      login: loginHandler, 
      logout: logoutHandler, 
      tokenExpired: tokenExpired
  }

     return (
         <AuthContext.Provider value={contextValue}>
           {props.children}
         </AuthContext.Provider>
     )
}

export default AuthContext; 