import { useState, useContext, Fragment } from "react";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import config from "../../store/app.config";

interface AuthReturn {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
}
const LoginForm = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const baseUrl = config['API_BASE_URL']; 
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitAuthCredentials = (email: string, password: string) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      fetch(`${baseUrl}api/auth`, {
        method: "POST",
        body: JSON.stringify({
          EmailAddress: email,
          Password: password,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setIsLoading(false);
          if (res.ok) {
            return res.text();
          } else {
            if (res.status === 401) {
              setErrorMessage(
                "Authentification failed. Please check your credentials and try again."
              );
            }
          }
        })
        .then((data) => {
          var authObject: AuthReturn = JSON.parse(data);
          authCtx.login(authObject.accessToken);
          let decodedToken: any = jwt_decode(data);
          if (decodedToken.admin == "True") {
            navigate("/UserAdmin");
          } else {
            const url = "/Organisation/" + decodedToken.id;
            navigate(url);
          }
        });
    } catch (Error) {
      setErrorMessage("Network Error");
    }
  };

  return (
    <Fragment>
      <div className="text-center mt-2">
        <h2>Welcome</h2>
        <p className="text-muted">Sign in to continue</p>
      </div>
      {errorMessage.length > 0 && (
        <Alert variant="danger">{errorMessage}</Alert>
      )}
      <Formik
        initialValues={{ Email: "", Password: "" }}
        onSubmit={(values, actions) => {
          submitAuthCredentials(values.Email, values.Password);
        }}
        validationSchema={Yup.object().shape({
          Email: Yup.string()
            .email("Email format is invalid.")
            .required("Enter your email address."),
          Password: Yup.string()
            .required("Enter your password.")
            .min(8, "Password too short. Must be at least 8 characters long."),
        })}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="Email"
                type="email"
                value={values.Email}
                onChange={handleChange}
                placeholder="Enter your email"
                onBlur={handleBlur}
                className={errors.Email && touched.Email && "error"}
                tabIndex={1}
              />
            </Form.Group>
            {errors.Email && touched.Email && (
              <div className="input-feedback">{errors.Email}</div>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="Password"
                type="password"
                placeholder="Enter your password"
                value={values.Password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.Password && touched.Password && "error"}
                tabIndex={2}
              ></Form.Control>
              {errors.Password && touched.Password && (
                <div className="input-feedback mt-1">{errors.Password}</div>
              )}
            </Form.Group>
            <div className="d-grid gap-2">
              {!isLoading && (
                <Button variant="primary" type="submit" tabIndex={3}>
                  Sign in
                </Button>
              )}
              {isLoading && (
                <Button variant="primary" disabled tabIndex={3}>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Sending request...
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default LoginForm;
