import { useTable, Column } from "react-table";
import React, { useContext, useState } from "react";
import { FaUserEdit } from "react-icons/fa";
import { GrDocumentMissing } from "react-icons/gr";
import { MdEdit } from "react-icons/md";
import { User } from "../../Interfaces/IUser";
import { useNavigate } from "react-router-dom";
import Pagination from "../UI/Pagination";
import { ResetUserPassword } from "../../Services/UserServices";
import AuthContext from "../../store/auth-context";
import {TiTick} from "react-icons/ti"; 
import ConfirmationModal from "../UI/ConfirmationModal";

type UserAdminTableProps = {
  data: User[];
  currentPage: number;
  totalPages: number; 
  pageSize: number;
  total: number;
  refresh(pageNum: number, pagesize: number): any;
};

const UserTable = (props: UserAdminTableProps) => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const EditUser = (data) => {
    let url = "/EditUser/" + data.value;
    navigate(url);
  };
  const [showConfirmation, setShowConfirmation] = useState(false); 
  const [confrimationMessage, setConfirmationMessage] = useState(""); 

  const OnConfirmClose = () =>{
    setShowConfirmation(false); 
  }

  const ValidateUser = (docId) => {
    let url = "/DocsValidate/" + docId;
    navigate(url);
  };

  const EditUserSims = (userId) => {
    let url = "/userSims/" + userId;
    navigate(url);
  };

  const UpdateUserPassword = async (userId)=>{

    const resetResult = await ResetUserPassword(userId, authCtx.token); 
    if(resetResult.success){
      setConfirmationMessage("Password reset");
    }else{
      setConfirmationMessage("Failed To Reset Password")
    }

    setShowConfirmation(true);
  }

  const columns: Column<any>[] = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "DocumentId",
        accessor: "documentId",
      },
      {
        Header: "Admin",
        accessor: "admin",
      },      
      {
        Header: "Organisation",
        accessor: "organisation",
        Cell: (e,{ value }) => 
          <button
            className="btn btn-text edit"
            onClick={() => {
              EditUser({ value });
            }}
          >
            <FaUserEdit /> {e.row.values.organisation}
          </button>
      },
      {
        Header: "Email",
        accessor: "emailAddress",
      },
      {
        Header: "Documents",
        id: "buttonValidate",
        accessor: "validated",
        disableSortBy: true,
        Cell: (e) =>
          e.row.values.admin === true ? (
            <span />
      ) : e.row.values.documentId === null ? (
          <span>
            <GrDocumentMissing /> No Document
          </span>
      ) :  e.value == false ? (
            <button
              className="btn btn-text edit"
              onClick={() => {
                ValidateUser(e.row.values.documentId);
              }}
            >
              <FaUserEdit /> Validate
            </button>
          ) : (
            <button
            className="btn btn-text edit"
            onClick={() => {
              ValidateUser(e.row.values.documentId);
            }}
          >
             <span>Validated</span>
          </button>
           
          ),
      },      
      {
        Header: "Sims",
        id: "simsEdit",

        disableSortBy: true,
        Cell: (e) => 
        e.row.values.admin === true ? (
          <span />
      ) :
        (
          <button
            className="btn btn-text"
            onClick={() => {
              EditUserSims(e.row.values.id);
            }}
          >
            <MdEdit /> Edit SIMs
          </button>
        ),
      },  
      {
        Header: "Reset Password",
        id: "passwordReset",

        disableSortBy: true,
        Cell: (e) => 
        e.row.values.admin === true ? (
          <span />
      ) :
        (
          <button
            className="btn btn-text"
            onClick={() => {
              UpdateUserPassword(e.row.values.id);
            }}
          >
            Reset Password
          </button>
        ),
      },    
      {
        Header: "Complete", 
        accessor: "complete", 
        Cell: (e)=>
        e.row.values.admin == true ? (
          <span />
      ) : (
        e.value ? (<TiTick/>): (<div></div>)
      )
      }
    ],
    [UserTable]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: props.data,
      initialState: {
        hiddenColumns: ["id", "documentId", "admin"],
      },
    });

  return (
    <div className="table-group">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        currentPage={props.currentPage}
        pageSize={props.pageSize}
        totalpages={props.totalPages}
        total={props.total}
        onChange={props.refresh}
      />
      <ConfirmationModal
            show={showConfirmation}
            titleText={"Reset Results"}
            text={confrimationMessage}
            buttonText=""
            handleOk={null}
            handleClose={OnConfirmClose}
            showActionButton={false}
          />
    </div>
  );
};

export default UserTable;
