import React, { Fragment, useContext } from "react";
import AuthContext from "../../store/auth-context";

import { NavDropdown } from "react-bootstrap";
import { HiLogout } from "react-icons/hi";
import { HiUserCircle } from "react-icons/hi";

import "../../styles/ProfileMenu.scss";
function ProfileMenu(props) {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <Fragment>
      <NavDropdown
        className="user"
        title={
          <div style={{ display: "inline-block" }} className="username">
            <span>
              <HiUserCircle className="icon" />
              {authCtx.decodedToken.email}
            </span>
          </div>
        }
        align="end"
      >
        <NavDropdown.Item onClick={logoutHandler}>
          <HiLogout /> Log out
        </NavDropdown.Item>
        
      </NavDropdown>
     
    </Fragment>
  );
}

export default ProfileMenu;
