import { Fragment, useContext, useEffect, useState } from "react";
import { Form, Row, Col, Card, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import { IdentityDocument } from "../Interfaces/IIdentityDocument";
import {DocumentFileDownload} from "../Interfaces/IDocumentFileDownload"
import {
  DownloadDocumentFile,
  GetDocumentById,
  UpdateDocumentData,
} from "../Services/DocumentService";
import AuthContext from "../store/auth-context";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../components/UI/ConfirmationModal";
import Toolbar from "../components/UI/Toolbar";
import { IoChevronBackOutline } from "react-icons/io5";
import { FieldArray } from "formik";

const defaultConfirmation: ConfirmationModalProps = {
  show: false,
  titleText: "",
  text: "",
  buttonText: "",
  handleOk: null,
  handleClose: null,
  showActionButton: false,
};

const DocsValidationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const [documentData, setDocumentData] = useState<IdentityDocument>();
  const [docBase64String, setDocBase64String] = useState<string>();
  const [docType, setDocType] = useState<string>();
  let { docId } = useParams();
  const [modalData, setModalData] =
    useState<ConfirmationModalProps>(defaultConfirmation);

  const GetDocumentData = async () => {
    setIsLoading(true);
    const response = await GetDocumentById(docId.toString(), authCtx.token);
    if (response.success) {
      var docToVaidate: IdentityDocument={
        id:response.data.id, 
        userId : response.data.userId, 
        firstName: response.data.firstName, 
        lastName: response.data.lastName, 
        referenceNumber: response.data.referenceNumber, 
        countryOfIssue: response.data.countryOfIssue, 
        validated: response.data.validated, 
        expirationDate: new Date(response.data.expirationDate)
      }
      setDocumentData(docToVaidate);
      const imageResponse = await DownloadDocumentFile(
        docId.toString(),
        authCtx.token
      );
      if (imageResponse.success) {
        let fileData: DocumentFileDownload = imageResponse.data
      setDocBase64String(fileData.imageBase64);
      setDocType(fileData.contentType);
      } else {
        setModalData((prevData) => ({
          ...prevData,
          title: "Error",
          text: "Failed To Get Data",
          handleClose: { OnConfirmationClose },
        }));
      }
    } else {
      setModalData((prevData) => ({
        ...prevData,
        title: "Error",
        text: "Failed To Get Data",
        handleClose: { OnConfirmationClose },
      }));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetDocumentData();
  }, []);

  const ValidateDocument = async () => {
    let document = documentData;
    document.validated = true;

    const updateRespone = await UpdateDocumentData(document, authCtx.token);
    if (updateRespone.success) {
      navigate("/UserAdmin");

    } else {
      setModalData((prevData) => ({
        ...prevData,
        title: "Error",
        text: "Failed Update Record",
        handleClose: { OnConfirmationClose },
      }));
    }
  };

  const OnConfirmationClose = () => {
    setModalData(defaultConfirmation);
  };

  return (
    <>
      <Toolbar buttons={[]} title="Validate" />
      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <Fragment>
          <Row className="align-items-center justify-content-center">
            <Col md={9} lg={9} xl={9}>
              <Form>
                <Card className="my-4 p-5">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      readOnly
                      value={documentData.firstName}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      readOnly
                      value={documentData.lastName}
                    ></Form.Control>
                    <Form.Group>
                      <Form.Label>Reference Number</Form.Label>
                      <Form.Control
                        readOnly
                        value={documentData.referenceNumber}
                      ></Form.Control>
                      <Form.Group>
                        <Form.Label>Country Of Issue</Form.Label>
                        <Form.Control
                          readOnly
                          value={documentData.countryOfIssue}
                        ></Form.Control>
                        <Form.Group>
                          <Form.Label>Expiration Date</Form.Label>
                          <Form.Control
                            readOnly
                            value={documentData.expirationDate.toDateString()}
                          ></Form.Control>
                        </Form.Group>
                      </Form.Group>
                    </Form.Group>
                  </Form.Group>
                 
                 <div className="image">
                 {docType == "image/png" || docType == "image/jpeg" && (
                    <img width="50%" src={`data:${docType};base64,${docBase64String}`} />
                  )}
                  {docType == "application/pdf"  && (
                    <iframe width="100%" height="100%" src={`data:${docType};base64,${docBase64String}`} />
                  )}

                 </div>
                  
                </Card>

                

                <div className="d-grid justify-content-center gap-2 d-md-flex">
                  <Button
                    className="btn btn-secondary"
                    onClick={() => {
                      navigate("/UserAdmin");
                    }}
                  >
                    <IoChevronBackOutline /> Return to User Admin
                  </Button>
                  <Button
                    type="submit"
                    disabled={documentData.validated}
                    onClick={ValidateDocument}
                    className="btn btn-primary"
                  >
                    Validate
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Fragment>
      )}
      <ConfirmationModal
        show={modalData.show}
        titleText={modalData.titleText}
        text={modalData.text}
        buttonText={modalData.buttonText}
        handleOk={modalData.handleOk}
        handleClose={modalData.handleClose}
        showActionButton={modalData.showActionButton}
      />
    </>
  );
};

export default DocsValidationPage;
